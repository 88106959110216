.owl-carousel {
    .owl-nav {
        @include media-breakpoint-down(md) {
            display: none;
        }

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: space-between;
        width: 100%;
        .owl-next,
        .owl-prev {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ddd !important;
            color: white !important;
            width: 40px;
            height: 40px;
            border-radius: 20px;
        }
    }
    &.carousel-products {
        .owl-next {
            margin-right: -60px;
        }
        .owl-prev {
            margin-left: -60px;
        }
    }
}

.full-size-carousel-item {
    width: 100%;
    height: 450px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


.bg-light-green-1 {
    background-color: $greenLight1
}

.bg-light-green-2 {
    background-color: $greenLight2
}

.bg-gray-200 {
    background-color: $gray-200;
}
.bg-dark-gray {
    background-color: #e1e2e3;
}

.color-fb {
    color: $color-fb;
}
.color-google {
    color: $color-google;
}

.text-underline {
    display: inline-block;
    &.text-primary {
        position: relative;
        &:before {
            content: "";
            background-color: $primary;
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}

.delivery-alert > * {
    margin-bottom: 0;
}

.text-white-all {
    color: white;
    *:not(a) {
        color: white;
    }
}

@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?3r2mt7');
    src:  url('fonts/icomoon.eot?3r2mt7#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?3r2mt7') format('truetype'),
    url('fonts/icomoon.woff?3r2mt7') format('woff'),
    url('fonts/icomoon.svg?3r2mt7#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.circle-icon {
    border-radius: 100%;
    border: 1px solid $gray-300;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}


.icon-badge {
    position: relative;
    .badge {
        min-width: 16px;
        height: 16px;
        line-height: 9px !important;
        font-size: 10px;
        padding: 0 4px !important;
        position: absolute;
        right: 50%;
        transform: translateX(120%);
        top: 0;
    }
}


.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.icon-x2 {
        font-size: 24px;
    }
    &.icon-x3 {
        font-size: 28px;
    }

    &.icon-pint:before {
        content: "\e90f";
        color: $color-pinterest
    }
    &.icon-google-plus:before {
        content: "\e90a";
        color: $color-google;
    }
    &.icon-fb:before {
        content: "\e900";
        color: $color-fb;
    }
    &.icon-insta:before {
        content: "\e901";
        color: $color-insta;
    }
    &.icon-book:before {
        content: "\e902";
    }
    &.icon-photobook:before {
        content: "\e903";
    }
    &.icon-calendar:before {
        content: "\e904";
    }
    &.icon-star:before {
        content: "\e905";
    }
    &.icon-cart:before {
        content: "\e906";
    }
    &.icon-search:before {
        content: "\e907";
    }
    &.icon-user:before {
        content: "\e908";
    }
    &.icon-projects:before {
        content: "\e909";
    }
    &.icon-bell:before {
        content: "\e90b";
    }
    &.icon-pencil:before {
        content: "\e90c";
    }
    &.icon-trash:before {
        content: "\e90d";
    }
    &.icon-trash-outline:before {
        content: "\e90e";
    }
}

.banner {
    position: relative;
    display: block;
    height: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .banner-inner {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        color: $white;
        padding: 1.5rem 5rem;
        text-align: left;
        strong {
            font-weight: bold;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            color: $white;
            font-weight: lighter;
            line-height: 150%;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.banner-product-list {
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    h2 {
        line-height: 3.2rem;
    }
}

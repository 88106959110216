.cart {
    ul.cart-list {
        li {
            border-bottom: 1px solid $gray-300;
            position: relative;
            .cart-item-loader {
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                background-color: rgba(255, 255, 255, 0.75);
            }
        }
    }
}


.image-listview {
    > li:after {
        display: none;
    }
    a {
        border-bottom: 1px solid $gray-300;
        &.active {
            background-color: $gray-200;
            position: relative;

            ::after {
                content: "";
                left: 0;
                top: 0;
                position: absolute;
                height: 100%;
                width: 3px;
                background-color: $primary;
            }
        }
    }
}

.card {
  background: #ffffff;
  border-radius: $borderRadius;
  border: 0;
  box-shadow: $boxShadow;
  .card-body {
    padding: 24px 16px;
    line-height: 1.4em;
  }
  .card-title {
    color: $colorHeading;
    font-size: $fontSizeHeadingLarge;
    font-weight: $bold;
  }
  .card-subtitle {
    color: $colorText;
    font-weight: $medium;
    letter-spacing: .01em;
    font-size: $fontSizeCaption;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  .listview {
    > li:first-child {
      .item {
        border-radius: $borderRadius $borderRadius 0 0;
      }
    }
    > li:last-child {
      .item {
        border-radius: 0 0 $borderRadius $borderRadius;
      }
    }
  }
  .card-header {
    background: transparent;
    color: $colorHeading;
    font-weight: $medium;
    padding: 12px 16px;
    border-bottom: 1px solid $colorLine;
  }
  .card-footer {
    background: transparent;
    border-top: 1px solid $colorLine;
    color: $colorText;
    padding: 12px 16px;
  }
  .card-img-top {
    border-radius: $borderRadius $borderRadius 0 0;
  }
  .overlay-img {
    border-radius: $borderRadius;
  }
  .card-img-overlay {
    background: rgba(0, 0, 0, 0.5);
    border-radius: $borderRadius;
    .card-title {
      color: #fff;
    }
    .card-text {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  &.bg-primary,
  &.bg-secondary,
  &.bg-danger,
  &.bg-success,
  &.bg-warning,
  &.bg-info,
  &.bg-dark {
    border: 0;
    .card-title {
      color: #fff;
    }
    .card-text {
      color: rgba(255, 255, 255, 0.7);
    }
    .card-header {
      color: #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }
  }
  &.bg-light {
    background: #fff;
  }
}

.card.product-card{
  .card-body{
    padding: 8px;
  }
  .image{
    width: 100%;
    border-radius: $borderRadius;
  }
  .title{
    font-size: $fontSize;
    margin: 10px 0 0 0;
    padding: 0 4px;
    font-weight: $medium;
  }
  .text{
    font-size: $fontSizeCaption;
    color: $colorLight;
    margin: 0;
    padding: 0 4px 10px 4px;
  }
  .price{
    margin: 0 0 10px 0;
    padding: 0px 4px;
    display: block;
    font-size: $fontSize;
    font-weight: $medium;
    color: $colorPrimary;
  }
}

.product_card {
    p {
        color: $gray-600
    }
}

@include media-breakpoint-up(lg) {
    .weprint-navbar {
        position: relative;

        .navbar-nav {
            &:after {
                content: "";
                background-color: $colorPrimary;
                background: linear-gradient(90deg, $colorPrimary 0%, $colorSecondary 25%);
                position: absolute;
                left: 0;
                top: 0;
                width: 1500px;
                height: 100%;
                z-index: 1;
                margin-top: -37px;
                margin-left: -15px;
                padding-bottom: 115px;
                padding-left: 65px;
            }

            li.nav-item {
                z-index: 2;

                a,
                a.nav-link {
                    &:not(.dropdown-item) {
                        color: $white;
                    }
                }
            }
        }
    }
}

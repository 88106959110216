.weprint_notifications {
    &_sidebar {
        padding: 1.75rem 1.125rem;
        &__title {
            color: $primary;
            text-transform: uppercase;
            padding: .5rem 0 0;
            border-bottom: 1px solid lighten($primary,30%);
        }

        &__list {
            padding: 0;
            margin: 0 0 1rem;
            list-style: none;
            li {
                font-size: 13px;
                line-height: 1rem;
                border-bottom: 1px solid $gray-200;
                padding: .5rem 0;
            }
        }
    }
}

.accordion {
    .item {
        &:last-of-type {
            border-bottom: none;
            .radio-accordion-header {
                border-bottom: none;
            }
        }
        .radio-accordion-header {
            padding: 1rem 1rem;
            border-bottom: 1px solid $gray-400;

            .btn {
                position: relative;
                padding-left: 40px;
                color: $primary;

                &:before {
                    background-color: $primary;
                    border: 1px solid $primary;
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &.collapsed {
                    color: $colorText;
                    &:before {
                        background-color: $white;
                        border: 1px solid $gray-400;
                    }
                }
            }
        }
    }
}

.category-header {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 6rem 0;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1;
    }
    .container {
        position: relative;
        z-index: 2;
    }
}

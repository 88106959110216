$default:       #00449C !default;
$primary:       #00449C !default;
$secondary:     #00C72F !default;
$gray-200: #f7f7f7 !default;
$color-fb: #3a5794;
$color-google: #da4b42;
$color-pinterest: #cc2d2d;
$color-insta: #333;

$theme-colors: () !default;
$theme-colors: map-merge((
    "default":      $default,
    "primary":      $primary,
    "secondary":    $secondary,
), $theme-colors);

$breadcrumb-bg: white;
$breadcrumb-divider: quote(">") !default;
$breadcrumb-padding-x: 0 !default;


$navbar-light-color: $primary;
$navbar-brand-padding-y: 1.25rem;

// NoUISlider

$noui-target-bg:                  #eceeef !default;
$noui-target-thickness:           5px !default;
$noui-target-border-radius:       5px !default;
$noui-target-border-color:        0 !default;
$noui-target-box-shadow:          inset 0 1px 2px rgba(90,97,105,.1) !default;

$noui-slider-connect-bg:          $primary !default;
$noui-slider-connect-disabled-bg: #b2b2b2 !default;

$noui-handle-width:               15px !default;
$noui-handle-bg:                  $primary !default;
$noui-handle-border:              0 !default;
$noui-handle-border-radius:       100% !default;

$noui-origin-border-radius:       2px !default;

$gb_white: #fff;
$gb_blue: #4285F4;
$gb_active-blue: #1669F2;

.google-btn {
    height: 42px;
    background-color: $gb_white;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
    display: flex;
    align-items: center;
    .google-icon-wrapper {
        margin-left: 1px;
        width: 40px;
        height: 40px;
        border-radius: 2px;
        background-color: $gb_white;
    }
    .google-icon {
        position: absolute;
        margin-top: 11px;
        margin-left: 11px;
        width: 18px;
        height: 18px;
    }
    .btn-text {
        color: #444;
        font-size: 14px;
        letter-spacing: 0.2px;
        font-family: "Roboto";
        height: 100%;
        padding: 0.5rem 1rem .5rem .5rem;
        margin: 0;
        white-space: nowrap;
    }
    &:hover {
        box-shadow: 0 0 6px $gb_blue;
    }

}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);


.btn-login-facebook {
    height: 42px;
    background-color: white;
    padding: .5rem 1rem .5rem .5rem !important;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25) !important;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
    color: #444;

    .icon {
        margin-left: 1px;
        width: 35px;
        height: 40px;
        border-radius: 2px;
        font-size: 22px;
        background-color: $gb_white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        box-shadow: 0 0 6px $gb_blue !important;
    }
}

.product_card {
    .product-img {
        min-width: 100%;
        height: 0;
        padding-top: 144%;
        background-color: $greenLight1;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }
}

.product_types_filter {
    .disabled {
        font-size: 12px;
    }
}

.highlight-product {
    display: block;
    position: relative;
    font-size: $fontSizeSub;
    font-weight: bold;
    .hover-text {
        @include transition(opacity 0.15s linear);
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        color: white;
        background-color: rgba($primary, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 1.5rem;
    }
    &:hover {
        .hover-text {
            opacity: 1;
        }
    }
}
